import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';

import type { Form } from '@feathr/blackbox';
import { CardV2 as Card } from '@feathr/components';

import Metadata from './Metadata';
import Preview from './Preview';

import * as styles from './Information.css';

interface IProps {
  form: Form;
}

function Information({ form }: Readonly<IProps>): JSX.Element {
  return (
    <Card width={'full'}>
      <Card.Content contentClassName={styles.root}>
        <div className={styles.container}>
          <Preview form={form} />
        </div>
        <div className={styles.container}>
          <Metadata form={form} />
        </div>
      </Card.Content>
    </Card>
  );
}

export default observer(Information);
