import { ObjectId } from 'bson';
import type AggregateError from 'es-aggregate-error';
import type { IObservableArray, ObservableMap } from 'mobx';
import { action, computed, makeObservable, observable, runInAction, set, toJS, values } from 'mobx';

import { fromQueryString, getQuery, parseUri, removeEmpty, toQueryString } from '@feathr/hooks';

import type { IBaseAttributes, Keys, Model as RachisModel } from './model';
import type {
  IPaginationResponse,
  IWretchResponseError,
  IWretchResponseValid,
  TRachisEmpty,
} from './wretch';
import { isWretchError } from './wretch';
import wretch from './wretch';

interface ICollectionAPIResponse<Model> {
  [s: string]: Model[];
}

type TPagedCollectionAPIResponse<Model> = ICollectionAPIResponse<Model> & {
  pagination?: IPagination;
};

interface IAPICacheEntry<T> {
  error: null | Error;
  fetchMore: () => void;
  fetchOptions: Partial<RequestInit>;
  isErrored: boolean;
  isPending: boolean;
  models: IObservableArray<T>;
  pagination: IPaginationResponse;
  url?: string;
}

export class ListResponse<Model extends RachisModel<IBaseAttributes>> {
  @computed
  get models(): IObservableArray<Model> {
    if (this.listId === undefined) {
      // If this is a standard list, the models must be mutable by the consumer
      const cache = this.getCache();
      return cache ? cache.models : observable.array();
    }
    return this.apiKeys.reduce(
      (previousValue, currentValue) => {
        previousValue.push(...this.collection.apiCache[currentValue].models);
        return previousValue;
      },
      // Needs to be an observable array to match IAPICacheEntry interface
      observable.array() as IObservableArray<Model>,
    );
  }

  @computed get error(): Error | null {
    const cache = this.getCache();
    return cache ? cache.error : null;
  }

  @computed get isErrored(): boolean {
    const cache = this.getCache();
    return cache ? cache.isErrored : false;
  }

  @computed get isPending(): boolean {
    const cache = this.getCache();
    return cache ? cache.isPending : false;
  }

  @computed get isLoaded(): boolean {
    return !this.isPending;
  }

  set isPending(value: boolean) {
    const cache = this.getCache();
    if (cache) {
      set(cache, 'isPending', value);
    }
  }

  @computed get pagination(): IPaginationResponse {
    const cache = this.getCache();
    return cache
      ? cache.pagination
      : {
          page_size: 0,
          page: 0,
          count: 0,
          pages: 0,
        };
  }

  @computed private get apiKeys(): string[] {
    if (this.url === undefined) {
      return [];
    }
    if (this.listId === undefined) {
      return [this.url];
    }
    const cache = this.collection.apiCachePages.get(this.listId);
    if (!cache) {
      return [this.url];
    }

    return this.collection.apiCacheKeys.filter((key) => cache.includes(key));
  }

  private collection: Collection<Model>;

  private listId?: string;

  private url?: string;

  public constructor(collection: Collection<Model>, url?: string, listId?: string) {
    makeObservable(this);
    this.collection = collection;
    this.listId = listId;
    this.url = url;

    if (this.listId && this.url && this.collection.apiCachePages.get(this.listId) === undefined) {
      this.collection.apiCachePages.set(this.listId, observable.array([this.url]));
    }
  }

  @action.bound
  public fetchMore(): void {
    if (!this.listId) {
      throw new Error('List Id is not set');
    }

    if (this.apiKeys.length < 1) {
      throw new Error('URL is not set');
    }

    const url = this.collection.fetchMore(this.apiKeys.slice(-1)[0]);
    const cache = this.collection.apiCachePages.get(this.listId);
    if (cache) {
      cache.push(url);
    }
  }

  private getCache(): IAPICacheEntry<Model> | undefined {
    if (this.apiKeys.length < 1) {
      return;
    }

    const url = this.apiKeys.slice(-1)[0];
    return this.collection.apiCache[url];
  }
}

export interface IObject {
  [s: string]: any;
}

export interface IListOptions {
  reset: boolean;
  fetchOptions: Partial<RequestInit>;
  url: string;
}

export interface IListParams<T> {
  filters?: IObject;
  /** A string of keywords to search for in Elasticsearch using the quick filter. */
  keywords?: string;
  only?: Array<Keys<T>>;
  exclude?: Array<Keys<T>>;
  ordering?: string[];
  pagination?: IPagination;
}

export interface IGetOptions {
  fetchOptions: Partial<RequestInit>;
}

export interface IGetParams<T> extends IObject {
  exclude?: Array<Keys<T>>;
  only?: Array<Keys<T>>;
}

export interface IPagination {
  page_size?: number;
  page?: number;
  distinct?: string;
}

export interface IAddOptions {
  validate: boolean;
  refreshApiCache: boolean;
  url: string;
}

export type Attributes<T extends RachisModel<IBaseAttributes>> =
  T['attributesType'] extends IBaseAttributes
    ? {
        [P in keyof T['attributesType']]: T['attributesType'][P];
      }
    : never;

export type AttributeKeys<T extends RachisModel<IBaseAttributes>> = Keys<Attributes<T>>;

export interface ICollectionStore {
  [key: string]: Collection<RachisModel<IBaseAttributes>> | undefined;
}

export abstract class Collection<Model extends RachisModel> {
  @computed
  public get models(): readonly Model[] {
    return values(this.modelsById as ObservableMap<string, Model>);
  }

  public get length(): number {
    return this.modelsById.size;
  }

  public methods = {
    list: 'GET',
  };

  public apiCache: Record<string, IAPICacheEntry<Model>> = {};

  public apiCacheKeys: IObservableArray<string> = observable.array([]);

  @observable
  public apiCachePages: ObservableMap<string, IObservableArray<string>> = observable.map();

  @observable
  /*
   * Because ObservableMap<string, Model> messes with inheritance, we need to instead use Map<string, Model> (which
   * the prior extends), and in subsequent code that uses this cast it as ObservableMap<string, Model>.
   */
  protected modelsById: Map<string, Model> = observable.map();

  protected store: ICollectionStore = {};

  public constructor(
    initialModels: Array<Partial<Attributes<Model>>>,
    store: ICollectionStore = {},
  ) {
    makeObservable(this);
    initialModels.forEach((model) => {
      this.create(model);
    });
    this.store = store;
  }

  public getFromStore(collection: string): Collection<RachisModel<IBaseAttributes>> | undefined {
    return this.store[collection];
  }

  public abstract getModel(attributes: Partial<Attributes<Model>>): Model;

  public getHostname(): string {
    return '';
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public getHeaders(useAccountContext = true): HeadersInit {
    return {};
  }

  /**
   * @param listId Use the useId() hook to generate a unique listID.
   */
  public list(
    params: IListParams<Attributes<Model>> = {},
    { fetchOptions = {}, reset = false, url: baseUrl }: Partial<IListOptions> = {},
    listId?: string,
  ): ListResponse<Model> {
    const url = this.paramsToUrl(removeEmpty(params), baseUrl);

    if (!this.apiCache[url] || reset) {
      this.fetchApiCacheItem(url, fetchOptions);
    }

    return this.newListResponse(url, listId);
  }

  public fetchMore(url: string): string {
    const uri = parseUri(url);
    const params = uri.search ? fromQueryString<IListParams<Attributes<Model>>>(uri.search) : {};
    if (params.pagination === undefined) {
      const pagination = {};
      if (params['page[page]'] !== undefined) {
        pagination['page'] = params['page[page]'];
      }
      if (params['page[page_size]'] !== undefined) {
        pagination['page_size'] = params['page[page_size]'];
      }
      params.pagination = pagination;
    }
    params.pagination!.page = params.pagination!.page ? params.pagination!.page + 1 : 1;

    const cached = this.apiCache[url];
    const newUrl = uri.pathname!.replace(this.getHostname(), '');
    this.list(params, {
      fetchOptions: cached.fetchOptions,
      url: newUrl,
    });
    return this.paramsToUrl(params, newUrl);
  }

  public count(
    params: IListParams<Attributes<Model>> = {},
    options: Partial<IListOptions> = {},
  ): number {
    const countParams = { ...params, pagination: { page_size: 1 } };
    const results = this.list(countParams, options);
    return results.pagination.count;
  }

  public get(
    id: string,
    additionalAttrs: Partial<Attributes<Model>> = {},
    params: IGetParams<Attributes<Model>> = {},
    options: Partial<IGetOptions> = {},
    callback?: (model: Model) => void,
  ): Model {
    const { fetchOptions = {} } = options;

    const model = this.modelsById.get(id);
    if (!model) {
      const newModel = this.create({ id, ...additionalAttrs });
      this.fetchModel(newModel, params, fetchOptions, callback);

      if (params.only) {
        newModel.setAttributeFetched(...params.only);
      }

      return newModel;
    }

    let shouldRefetch = false;
    if (params.only) {
      params.only.forEach((attribute) => {
        if (!model.has(attribute as string)) {
          shouldRefetch = true;
          model.only.add(attribute as string);
        }
      });
    } else if (model.only.size && !model.isPending) {
      // If only is empty, we want all attributes; if we have a subset, refetch.
      shouldRefetch = true;
    }

    if (shouldRefetch) {
      this.fetchModel(model, params, fetchOptions, callback);
    }

    return model;
  }

  @action.bound
  public async fetchModel(
    model: Model,
    params: IGetParams<Attributes<Model>> = {},
    fetchOptions: Partial<RequestInit> = {},
    callback?: (model: Model) => void,
  ): Promise<Model> {
    const { id } = model;
    const queryParams = { ...model.fetchParams(), ...params };
    const search = toQueryString(queryParams);
    let url = `${this.url()}${id}`;
    if (search) {
      url = `${url}?${search}`;
    }
    model.isPending = true;
    const response = await wretch<Attributes<Model>>(url, {
      method: 'GET',
      headers: this.getHeaders(),
      ...fetchOptions,
    });
    if (isWretchError(response)) {
      this.processErrorResponse(model, response.error);
      return model;
    }
    const out = this.processJSONResponse(response, params.only);
    if (callback) {
      callback(out);
    }
    return out;
  }

  @action
  public async save(
    id: string,
    patch: Partial<Attributes<Model>> = {},
    fetchOptions = {},
  ): Promise<Model> {
    const model = this.modelsById.get(id);
    if (!model) {
      throw new Error(`Model with id ${id} does not exist in collection`);
    }

    const body = JSON.stringify(
      {
        ...model.toJS(),
        ...patch,
      },
      (_, v) => (v === undefined ? null : v),
    );
    model.isUpdating = true;
    const response = await wretch<Attributes<Model>>(`${this.url()}${id}`, {
      method: 'PUT',
      body,
      headers: this.getHeaders(),
      ...fetchOptions,
    });
    if (isWretchError(response)) {
      this.processErrorResponse(model, response.error);
      return model;
    } else {
      return this.processJSONResponse(response);
    }
  }

  /**
   * Identical to save, but only sends changed fields in payload.
   */
  @action
  public async patch(
    id: string,
    patch: Partial<Attributes<Model>> = {},
    fetchOptions: Partial<RequestInit> = {},
  ): Promise<Model> {
    const model = this.modelsById.get(id);
    if (!model) {
      throw new Error(`Model with id ${id} does not exist in collection`);
    }
    if (!Object.keys(patch).length) {
      return model;
    }

    const body = this.patchToString(patch);
    model.isUpdating = true;
    const response = await wretch<Attributes<Model>>(`${this.url()}${id}`, {
      method: 'PUT',
      body,
      headers: this.getHeaders(),
      ...fetchOptions,
    });
    if (isWretchError(response)) {
      this.processErrorResponse(model, response.error);
      return model;
    } else {
      return this.processJSONResponse(response);
    }
  }

  @action
  public async reload(id: string, only?: Array<AttributeKeys<Model>>): Promise<Model> {
    const model = this.modelsById.get(id);
    if (!model) {
      throw new Error(`Model with id ${id} does not exist in collection`);
    }

    model.isUpdating = true;
    const response = await wretch<Attributes<Model>>(`${this.url()}${id}`, {
      method: 'GET',
      headers: this.getHeaders(),
    });
    if (isWretchError(response)) {
      this.processErrorResponse(model, response.error);
      return model;
    } else if (!!only && only.length) {
      // Strip keys from response that are not in only
      const filteredResponse = Object.entries(response).reduce((prev, [key, value]) => {
        if (only.includes(key as AttributeKeys<Model>)) {
          prev[key] = value;
        }
        return prev;
      }, {});
      return this.processJSONResponse({ ...filteredResponse, id });
    } else {
      return this.processJSONResponse(response);
    }
  }

  @action.bound
  public create(defaultAttributes: Partial<Attributes<Model>> = {}): Model {
    const modelId = (defaultAttributes.id as string) || new ObjectId().toHexString();
    const model = this.getModel({ id: modelId, ...defaultAttributes });
    model.collection = this;
    model.isEphemeral = true;
    this.modelsById.set(model.id, model);
    return model;
  }

  @action.bound
  public async add(model: Model, options: Partial<IAddOptions> = {}): Promise<Model> {
    const { validate = true, refreshApiCache = true, url } = options;
    if (validate && !model.isValid()) {
      throw new Error('Model is invalid');
    }
    const response = await wretch<Attributes<Model>>(url || this.url(), {
      method: 'POST',
      body: JSON.stringify(model.toJS()),
      headers: this.getHeaders(),
    });

    if (isWretchError(response)) {
      this.processErrorResponse(model, response.error);
      return model;
    } else {
      if (refreshApiCache) {
        this.refreshApiCache();
      }
      return this.processJSONResponse(response);
    }
  }

  public async archive(id: string): Promise<void> {
    const model = this.modelsById.get(id)!;
    const patch = { is_archived: true } as Partial<Attributes<Model>>;
    model.set(patch);
    await this.patch(id, patch, {
      method: 'PATCH',
    });
    if (!model.isErrored) {
      this.remove(id);
    }
  }

  public async clone(id: string, data?: Partial<Attributes<Model>>): Promise<Model> {
    const body = data ? this.patchToString(data) : undefined;

    const response = await wretch<Attributes<Model>>(`${this.url()}${id}/clone`, {
      body,
      method: 'POST',
      headers: this.getHeaders(),
    });
    if (isWretchError(response)) {
      throw response.error;
    }
    return this.processJSONResponse(response);
  }

  @action.bound
  public clearApiCache(): void {
    Object.keys(this.apiCache).forEach((url) => {
      delete this.apiCache[url];
    });
  }

  @action.bound
  public refreshApiCache(): void {
    Object.keys(this.apiCache).forEach((url) => {
      this.fetchApiCacheItem(url, undefined, true);
    });
  }

  @action.bound
  public updateApiCache(id: string): void {
    Object.keys(this.apiCache).forEach((key) => {
      const i = this.apiCache[key].models.findIndex((item) => item.id === id);
      const model = this.modelsById.get(id);
      if (i > -1 && model) {
        this.apiCache[key].models[i] = model;
      }
    });
  }

  @action.bound
  public removeFromApiCache(id: string): void {
    Object.keys(this.apiCache).forEach((key) => {
      const model = this.apiCache[key].models.find((item) => item.id === id);
      if (model) {
        this.apiCache[key].models.remove(model);
      }
    });
  }

  public abstract getClassName(): string;

  // Variant must be optional and fall back to the url used by Collection.list()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public url(variant?: string, value?: string | string[]): string {
    return `${this.getHostname()}${this.getClassName()}/`;
  }

  public map(
    callbackfn: (value: Model, index: number, array: readonly Model[]) => Record<string, unknown>,
  ): Array<Record<string, unknown>> {
    return this.models.map(callbackfn);
  }

  public toJS(): Map<string, Model> {
    return toJS(this.modelsById);
  }

  @action.bound
  public remove(id: string): void {
    if (this.modelsById.has(id) && !this.modelsById.get(id)!.isEphemeral) {
      this.modelsById.delete(id);
    }
    this.removeFromApiCache(id);
  }

  @action
  public reset(): void {
    this.modelsById.clear();
    Object.keys(this.apiCache).forEach((key) => {
      this.apiCache[key].models.clear();
    });
    this.apiCache = {};
    this.apiCacheKeys.clear();
  }

  @action
  public async delete(id: string): Promise<void> {
    const model = this.modelsById.get(id);
    if (model) {
      model.isUpdating = true;
      const response = await wretch<TRachisEmpty>(`${this.url()}${id}`, {
        method: 'DELETE',
        headers: this.getHeaders(),
      });
      if (isWretchError(response)) {
        this.processErrorResponse(model, response.error);
      } else {
        runInAction(() => {
          model.isUpdating = false;
          model.isErrored = false;
          model.error = null;
          model.isPending = false;
        });
        this.remove(id);
      }
    }
  }

  public filter(callback: () => any): Model[] {
    return this.models.filter(callback);
  }

  public newListResponse(url?: string, listId?: string): ListResponse<Model> {
    return new ListResponse<Model>(this, url, listId);
  }

  protected newAPICacheEntry(
    fetchOptions: Partial<RequestInit> = {},
    url?: string,
  ): IAPICacheEntry<Model> {
    return observable({
      models: observable([]) as IObservableArray<Model>,
      isPending: false,
      isErrored: false,
      error: null,
      pagination: observable<IPaginationResponse>({
        page_size: 0,
        page: 0,
        count: 0,
        pages: 0,
      }),
      fetchOptions,
      url,
    } as IAPICacheEntry<Model>);
  }

  @action.bound
  protected fetchApiCacheItem(
    url: string,
    initialFetchOptions: Partial<RequestInit> = {},
    skipUpdateApiCache = false,
  ): void {
    const exists = !!this.apiCache[url];

    const query = getQuery<{ 'fields[data]': string }>(url);
    const only: Array<AttributeKeys<Model>> = query['fields[data]']?.split(',');

    const result = exists ? this.apiCache[url] : this.newAPICacheEntry(initialFetchOptions, url);

    if (!result.isPending) {
      result.isPending = true;
      result.models.replace([]);
      set(result.pagination, {
        page_size: 0,
        page: 0,
        count: 0,
        pages: 0,
        distinct: 0,
      });
      const asyncFetch = async (): Promise<void> => {
        const response = await wretch<Attributes<Model>>(url, {
          method: 'GET',
          headers: this.getHeaders(),
          ...result.fetchOptions,
        });
        if (isWretchError(response)) {
          runInAction(() => {
            result.error = response.error ?? null;
            result.isErrored = true;
            result.isPending = false;
          });
        } else {
          runInAction(() => {
            const processedResponse = this.processListJSONResponse(
              response,
              skipUpdateApiCache,
              only,
            );
            result.models.replace(processedResponse.models);
            if (processedResponse.pagination) {
              set(result.pagination, processedResponse.pagination);
            }
            set(result, {
              isPending: false,
              isErrored: false,
              error: null,
            });
          });
        }
      };
      asyncFetch();
    }
    if (!exists) {
      this.apiCache[url] = result;
      this.apiCacheKeys.push(url);
    }
  }

  protected patchToString(patch: Record<string, any>): string {
    return JSON.stringify(
      {
        ...patch,
      },
      (_, v) => (v === undefined ? null : v),
    );
  }

  protected parse(
    response: TPagedCollectionAPIResponse<Attributes<Model>>,
    only: Array<AttributeKeys<Model>>,
  ): Array<[string | undefined, Model]> {
    const modelsJSON = response['data'] || [];
    return modelsJSON.map((json) => [json.id, this.processJSONResponse(json, only)]);
  }

  @action.bound
  protected processListJSONResponse(
    json: any,
    skipUpdateApiCache = false,
    only: Array<AttributeKeys<Model>> = [],
  ): IAPICacheEntry<Model> {
    const listResponse = this.newAPICacheEntry();

    runInAction(() => {
      const idModelTuples = this.parse(json, only).filter(([id]) => id !== undefined) as Array<
        [string, Model]
      >;
      (this.modelsById as ObservableMap<string, Model>).merge(idModelTuples);
      const models = idModelTuples.map((tuple) => {
        if (!skipUpdateApiCache && tuple[0]) {
          this.updateApiCache(tuple[0]);
        }
        /*
         * Don't need to handle any only stuff here because that is already taken
         * care of in processJSONResponse via parse
         */
        return tuple[1];
      });
      listResponse.models.replace(models);
      if (json.meta?.page) {
        Object.assign(listResponse.pagination, json.meta.page);
      }
      set(listResponse, { isPending: false, isErrored: false, error: null });
    });

    return listResponse;
  }

  /**
   * Takes a JSON object representation of a model and adds it to the collection as a model.
   * If either the model does not exist or the model exists and should change class, replace it
   * with a new model. Otherwise, update the existing model.
   */
  @action.bound
  protected processJSONResponse(json: any, only: Array<AttributeKeys<Model>> = []): Model {
    const response = 'data' in json ? json.data : json;
    if (!response.id) {
      throw new Error('JSON response does not have an id');
    }
    let model = this.modelsById.get(response.id);
    /*
     * We store the existence of the model here so we can know if we need
     * to add it to the modelsById hash later
     */
    const modelExists = !!model;
    const modelHasWrongClass =
      !!model && response._cls !== undefined && model.get('_cls') !== response._cls;
    if (modelExists && modelHasWrongClass) {
      /*
       * For reasons unknown, I have to explicitly delete the model from the modelsById hash
       * in order for things depending on the model to be replaced to actually re-render with
       * the new model.
       */
      runInAction(() => {
        /*
         * We set model.isReplaced so that functions that depend on references to this model
         * can manually get the correct model
         */
        model!.isReplaced = true;
        // We set model.isPending so that observers that still depend on this model will react
        model!.isPending = false;
        this.modelsById.delete(model!.id);
      });
    }
    if (!modelExists || modelHasWrongClass) {
      model = this.getModel(response);
      model.id = response.id as string;
      model.collection = this;
    }
    // The model has to exist at this point, but typescript doesn't believe us
    if (model) {
      // Clear values that are requested but not included in json and sanitize the response
      const newValues = {
        ...this.fillOnlyWithUndefined(model, only),
        ...model.postFetch(response),
      };
      model.set(newValues, false);
      model.setAttributeClean(...Object.keys(newValues));
      if (only.length) {
        model.setAttributeFetched(...(only as string[]));
      } else {
        // If only is empty, all properties were fetched.
        model.clearAttributeFetched();
      }
      model.isPending = false;
      model.isUpdating = false;
      model.isFetched = true;
      model.isEphemeral = false;
      model.isErrored = false;
      model.error = null;
      if (!modelExists || modelHasWrongClass) {
        this.modelsById.set(model.id, model);
      }
      if (modelHasWrongClass) {
        this.updateApiCache(model.id);
      }
    }
    return model!;
  }

  protected processErrorResponse(model: Model, response: AggregateError): void {
    model.processErrorResponse(response);
  }

  public parseJSONParams(params: IListParams<Attributes<Model>>): Record<string, unknown> {
    // Input params. Modify filters, only, pagination, sort.
    const newParams = Object.entries(params).reduce(
      (newParams, [key, value]) => {
        if (key === 'filters') {
          // `filter` uses bracket system. But children of `filter` that are not a simple string are stringified json.
          Object.entries(value).forEach(([filterKey, filterValue]) => {
            newParams[`filter[${filterKey}]`] =
              typeof filterValue === 'string' || filterValue instanceof ObjectId
                ? filterValue
                : JSON.stringify(filterValue);
          });
        } else if (key === 'only') {
          newParams['fields[data]'] = value.join(',');
        } else if (key === 'exclude') {
          newParams['fields[data]'] = value.map((v) => `-${v}`).join(',');
        } else if (key === 'pagination') {
          Object.entries(value).forEach(([pageKey, pageValue]) => {
            newParams[`page[${pageKey}]`] = pageValue;
          });
        } else if (key === 'ordering') {
          newParams['sort'] = value.join(',');
        } else {
          /*
           * Add remaining keys.
           * Any key that is not `fields, `filter`, `page` or `sort` AND not a simple string should be stringified json.
           */
          newParams[key] =
            typeof value === 'string' || value instanceof ObjectId ? value : JSON.stringify(value);
        }
        return newParams;
      },
      {} as Record<string, unknown>,
    );

    return newParams;
  }

  public paramsToUrl(params: IListParams<Attributes<Model>> = {}, baseUrl?: string): string {
    const search = toQueryString(this.parseJSONParams(params));
    let url = this.url();
    if (baseUrl) {
      // TODO: Remove code stripping hostname when all calls to collection.list() use collection.url() to pass in baseUrl
      url = `${this.getHostname()}${baseUrl.replace(this.getHostname(), '')}`;
    }
    if (search) {
      url = `${url}?${search}`;
    }
    return url;
  }

  private fillOnlyWithUndefined(
    model: Model,
    only: Array<AttributeKeys<Model>> = [],
  ): Record<string, any> {
    // Clear values that are requested but not included in json.
    const modelKeys = Object.keys(model.attributes);
    return only
      .filter((value) => modelKeys.includes(value))
      .reduce(
        (a, c) => {
          a[c] = undefined;
          return a;
        },
        {} as Record<string, any>,
      );
  }
}

export abstract class BulkCollection<
  Model extends RachisModel<IBaseAttributes>,
> extends Collection<Model> {
  public bulkUrl(): string {
    return `${this.url()}bulk`;
  }

  public async bulk(
    ids: string[],
    patch: Partial<Attributes<Model>> = {},
    fetchOptions: Partial<RequestInit> = {},
  ): Promise<IAPICacheEntry<Model>> {
    if (!ids.length) {
      throw new Error('You must provide an array of object ids to bulk process.');
    }
    if (!Object.keys(patch).length) {
      throw new Error('You must provide a valid patch.');
    }

    const body = this.patchToString({ ids, fields: patch });

    const url = this.bulkUrl();
    const out = this.newAPICacheEntry();

    const apiResponse = await wretch<Array<Attributes<Model>>>(url, {
      method: 'PUT',
      body,
      headers: this.getHeaders(),
      ...fetchOptions,
    });
    if (isWretchError(apiResponse)) {
      set(out, {
        isErrored: true,
        error: apiResponse.error,
      });
    } else {
      const result = this.processListJSONResponse(apiResponse, true);
      set(out, result);
    }
    set(out, 'fetchOptions', fetchOptions);
    return out;
  }

  public async bulkDelete(
    ids: string[],
  ): Promise<IWretchResponseValid<TRachisEmpty> | IWretchResponseError> {
    const url = this.bulkUrl();
    const response = await wretch<TRachisEmpty>(url, {
      method: 'DELETE',
      body: this.patchToString({ ids }),
      headers: this.getHeaders(),
    });
    if (!isWretchError(response)) {
      this.refreshApiCache();
    }
    return response;
  }
}
