import type { TFunction } from 'i18next';

import { EFormState } from '@feathr/blackbox';

export function formStateThemeMap(state: EFormState): string {
  const map = {
    [EFormState.Draft]: 'default',
    [EFormState.Published]: 'sky',
    [EFormState.Archived]: 'violet',
  };

  return map[state];
}

export function formStateLabelMap(t: TFunction, state: EFormState): string {
  const map = {
    [EFormState.Draft]: t('Draft'),
    [EFormState.Published]: t('Active'),
    [EFormState.Archived]: t('Archived'),
  };

  return map[state];
}
