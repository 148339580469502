import { faCheck, faFileCheck, faShareNodes } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Prompt, useParams } from 'react-router';
import { ToastType } from 'react-toastify';

import {
  ActionBar,
  AlertV2 as Alert,
  Button,
  ButtonValid,
  Chip,
  EAlertV2Type,
  ETheme,
  Icon,
  Label,
  SaveButtonValid,
  Tab,
  Time,
  toast,
} from '@feathr/components';
import { useStore } from '@feathr/extender/state';
import { formStateLabelMap, formStateThemeMap } from '@feathr/extender/styles/forms';
import { errorMessage, TimeFormat, useToggle } from '@feathr/hooks';

import Page from '../../Page';
import { FormEditor } from './FormEditor';
import FormPublishModal from './FormPublishModal';
import FormReport from './FormReport';
import FormShareModal from './FormShareModal';
import Settings from './Settings';

import * as styles from './FormPage.css';

type TTab = 'report' | 'edit' | 'settings';

function FormPage(): JSX.Element {
  const { formId, tab } = useParams<{ formId?: string; tab?: TTab }>();

  const { Forms } = useStore();
  const [isPublishModalOpen, togglePublishModal] = useToggle(false);
  const [isShareModalOpen, toggleShareModal] = useToggle(false);

  if (!formId) {
    throw new Error('Form missing ID');
  }

  const { t } = useTranslation();
  const form = Forms.get(formId);
  const {
    date_last_published: lastPublished,
    date_last_modified: lastSaved,
    version,
    last_published_version: lastPublishedVersion,
    state,
  } = form.get([
    'date_last_published',
    'date_last_modified',
    'version',
    'last_published_version',
    'state',
  ]);
  const hasVersionDifference = version !== lastPublishedVersion;
  const [activeTab, setActiveTab] = useState<TTab>(tab ?? (form.published ? 'report' : 'edit'));
  const [isLoading, setIsLoading] = useState(false);
  const [hasValidationErrors, setHasValidationErrors] = useState(false);

  useEffect(() => {
    // Reset changed model if they weren't saved so
    // the user doesn't see the changes if they navigate away
    // and back to this page.
    return () => {
      if (form.isDirty) {
        form.discardDirty();
      }
    };
  }, [form]);

  function handleClickReportTab(): void {
    setActiveTab('report');
  }

  function handleClickEditTab(): void {
    setActiveTab('edit');
  }

  function handleClickSettingsTab(): void {
    setActiveTab('settings');
  }

  function handleValidationChange(hasErrors: boolean): void {
    setHasValidationErrors(hasErrors);
  }

  const tabs = [
    <Tab
      active={activeTab === 'edit'}
      key={'edit'}
      link={'edit'}
      onClick={handleClickEditTab}
      title={t('Edit')}
    />,
    <Tab
      active={activeTab === 'settings'}
      key={'settings'}
      link={'settings'}
      onClick={handleClickSettingsTab}
      title={t('Settings')}
    />,
  ];

  if (form.published) {
    tabs.unshift(
      <Tab
        active={activeTab === 'report'}
        key={'report'}
        link={'report'}
        onClick={handleClickReportTab}
        title={t('Report')}
      />,
    );
  }

  const actions = [
    <Button
      key={'share'}
      onClick={toggleShareModal}
      prefix={<Icon icon={faShareNodes} />}
      type={'secondary'}
    >
      {t('Share')}
    </Button>,
  ];

  async function handleRepublish(): Promise<void> {
    setIsLoading(true);
    try {
      const {
        message: { detail },
      } = await form.upsync();
      toast(t('Form updated:\n{{- message}}', { message: detail }), {
        type: ToastType.SUCCESS,
      });
      await form.reload();
      setIsLoading(false);
    } catch (error) {
      toast(t('Error upsyncing form:\n{{- error}}', { error: errorMessage(error, t) }), {
        type: ToastType.ERROR,
      });
      setIsLoading(false);
    }
  }

  const validationMessage = t('Fix errors to continue');
  // Disabled if there are validation errors, or no changes made + no version difference
  const isPublishDisabled = hasValidationErrors || (!hasVersionDifference && !form.isDirty);

  return (
    <Page
      actions={form.published ? actions : undefined}
      description={
        <div className={styles.chips}>
          {/* Our Icon component does not play nicely with Chip, so we use FontAwesomeIcon instead. */}
          <Chip prefix={<FontAwesomeIcon icon={faFileCheck} />} theme={ETheme.blue}>
            Forms
          </Chip>
          <Chip theme={formStateThemeMap(state)}>{formStateLabelMap(t, state)}</Chip>
        </div>
      }
      includeCurrentPageCrumb={true}
      legacyScrollClassName={styles.legacyScroll}
      tabs={tabs}
      title={t('Edit {{- name}}', { name: form.name })}
      truncateTitle={true}
    >
      <ActionBar
        left={
          activeTab !== 'report' ? (
            <span className={styles.leftActions}>
              <Label margin={'condensed'} weight={'semibold'}>
                {form.published ? t('Last published:') : t('Last saved:')}
              </Label>
              <Time
                className={styles.lastPublished}
                format={TimeFormat.longDateTime}
                formatLocal={true}
                showTimezone={true}
                timestamp={form.published ? lastPublished : lastSaved}
              />
            </span>
          ) : undefined
        }
        right={
          activeTab !== 'report' ? (
            <>
              <SaveButtonValid
                disabled={!form.isDirty || hasValidationErrors}
                errors={hasValidationErrors ? [validationMessage] : []}
                key={'save'}
                method={'patch'}
                model={form}
                successMessage={t('Your changes have been saved.')}
                type={'secondary'}
              />
              <ButtonValid
                disabled={isPublishDisabled}
                errors={hasValidationErrors ? [validationMessage] : []}
                key={'publish'}
                onClick={form.published ? handleRepublish : togglePublishModal}
                prefix={<Icon icon={faCheck} />}
                type={'success'}
              >
                {form.published ? t('Republish') : t('Publish')}
              </ButtonValid>
            </>
          ) : undefined
        }
        usePortal={true}
      />
      <Prompt
        message={t('You have unsaved changes. Are you sure you want to leave this page?')}
        when={form.isDirty}
      />
      {!isLoading && hasVersionDifference && form.published && activeTab !== 'report' && (
        <Alert
          title={t(
            'This version is newer than the published form. Republish to apply your changes.',
          )}
          type={EAlertV2Type.info}
        />
      )}
      {activeTab === 'report' && <FormReport form={form} />}
      {activeTab === 'edit' && (
        <FormEditor form={form} onValidationChange={handleValidationChange} />
      )}
      {activeTab === 'settings' && <Settings form={form} />}
      {isPublishModalOpen && (
        <FormPublishModal form={form} isOpen={isPublishModalOpen} toggle={togglePublishModal} />
      )}
      {isShareModalOpen && (
        <FormShareModal form={form} isOpen={isShareModalOpen} toggle={toggleShareModal} />
      )}
    </Page>
  );
}

export default observer(FormPage);
