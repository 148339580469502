import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useEffect } from 'react';

import type { Campaign, IConfig } from '@feathr/blackbox';
import { CampaignLabelMap } from '@feathr/blackbox';
import ReportWrapper from '@feathr/extender/components/ReportWrapper';
import { type IUseReportExportReturn } from '@feathr/extender/hooks';
import { useAccount } from '@feathr/extender/state';
import { logUserEvents } from '@feathr/hooks';
import { CampaignReport, ReportProvider } from '@feathr/report_components';

interface IProps extends IUseReportExportReturn<IConfig> {
  campaign: Campaign;
}

function CampaignReportSection({ campaign, ...reportExportProps }: Readonly<IProps>): JSX.Element {
  const account = useAccount();

  useEffect(() => {
    logUserEvents({
      [`Viewed ${CampaignLabelMap.get(campaign.get('_cls'))} report`]: {
        account_id: account.id,
        campaign_id: campaign.id,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <ReportProvider>
      <ReportWrapper
        hideCustomDateRange={campaign.isEmail}
        model={campaign}
        Report={CampaignReport}
        {...reportExportProps}
        showVerifiedActivityToggle={campaign.isEmail && campaign.get('show_bot_toggle')}
      />
    </ReportProvider>
  );
}

export default observer(CampaignReportSection);
