import type { IBaseAttributes, TConstraints } from '@feathr/rachis';
import { isWretchError, wretch } from '@feathr/rachis';

import type { ISegment } from '../segments';
import type { ITargetable } from '../targetables';
import type { ITargeting } from '../targetings';
import { DisplayCampaign } from './display';
import type { IMonetizationAttributes } from './monetization';
import type { CampaignClass, ICampaignAttributes } from './types';

export enum EBiddingStrategyClass {
  /** User selected auto-optimize for backend to auto optimize the bidding strategy after publish */
  Auto = 'Auto',
  /** User selected customize to provide specific bidding strategy values */
  Manual = 'Manual',
}

export interface IBiddingStrategy {
  _cls: EBiddingStrategyClass;
  base_bid: number;
  /** If the user saved a non-default bid value */
  custom_bid: boolean;
  /**
   * To enable cross device, pass undefined on the frontend so the backend falls back to the
   * right vendor on save. Passing 0 turns off cross device for the given campaign.
   *
   * Cross device should never be enabled for monetization campaigns */
  cross_device_vendor_id?: number;
  /**
   * Maximum bid capped at 2x base bid.
   * If "legacy" (published and the initial bid is under $5) it's 3x base bid.
   */
  max_bid: number;
  /** The amount of times in the frequency period that a user can see the ad */
  freq_cap: number;
  /** The amount of time (in minutes) before the frequency cap for a user resets */
  freq_period: number;
  /** Tradedesk feature that lowers bid price based on predicting bid competition */
  readonly predictive_clearing?: boolean;
}

export interface ITTDCampaignAttributes extends ICampaignAttributes, IMonetizationAttributes {
  readonly _cls:
    | CampaignClass.Segment
    | CampaignClass.Lookalike
    | CampaignClass.SeedSegment
    | CampaignClass.Affinity
    | CampaignClass.Search
    | CampaignClass.EmailList
    | CampaignClass.MobileGeoFencing
    | CampaignClass.MobileGeoFenceRetargeting;
  bidding_strategy: IBiddingStrategy;
  destination_url: string;
}

export interface IEphemeralTargetableOrSegment extends Omit<ITargeting, 'target_data'> {
  target_data: Partial<ITargetable> | Partial<ISegment> | undefined;
}

interface IRecommendedBudgetPayload {
  campaign: Partial<ICampaignAttributes>;
  targetings: IEphemeralTargetableOrSegment[];
}

interface IRecommendedBudgetResponse extends IBaseAttributes {
  recommended_budget: number;
}

export abstract class TTDCampaign<
  T extends ITTDCampaignAttributes = ITTDCampaignAttributes,
> extends DisplayCampaign<T> {
  public override get constraints(): TConstraints<T> {
    return super.constraints;
  }

  public static override readonly defaults: Partial<ITTDCampaignAttributes> &
    Pick<Required<ITTDCampaignAttributes>, 'bidding_strategy'> = {
    bidding_strategy: {
      _cls: EBiddingStrategyClass.Auto,
      base_bid: 5,
      custom_bid: false,
      max_bid: 10,
      freq_cap: 9,
      freq_period: 480,
    },
  };

  public override getDefaults(): Partial<T> {
    return {
      ...super.getDefaults(),
      ...TTDCampaign.defaults,
    } as Partial<T>;
  }

  public async getRecommendedBudget(
    payload?: IRecommendedBudgetPayload,
  ): Promise<IRecommendedBudgetResponse> {
    this.assertCollection(this.collection);

    const url = `${this.collection.url()}${this.id}/budget_recommendation`;

    const response = await wretch<IRecommendedBudgetResponse>(url, {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: this.collection.getHeaders(),
    });

    if (isWretchError(response)) {
      throw response.error;
    }

    return response.data;
  }
}

export class TTDBaseCampaign extends TTDCampaign<ITTDCampaignAttributes> {}
