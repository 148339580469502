import { action, computed, makeObservable, runInAction } from 'mobx';

import { concatPath } from '@feathr/hooks';
import type { Attributes, IBaseAttributes, IRachisMessage, TConstraints } from '@feathr/rachis';
import { Collection, DisplayModel, isWretchError, wretch } from '@feathr/rachis';

import type { FieldDataType } from './custom_fields';
import type { IRedirectDomain } from './redirect_domains';

interface IFormStats {
  num_crumbs: number;
  num_views: number;
  num_submissions: number;
  submission_rate: number;
}

export enum EFormState {
  Draft = 'draft',
  Published = 'published',
  Archived = 'archived',
}

export interface IRowItem {
  /** The u_key of the related custom field */
  readonly id: string;
  /** Read only name of the field */
  readonly name: string;
  readonly type: FieldDataType;
  help_text?: string;
  /** User configurable name of the field */
  label: string;
  placeholder?: string;
  required?: boolean;
}

export interface IListRowItem extends IRowItem {
  options: string[];
  type: FieldDataType.list;
  multi: boolean;
}

export interface IFormConfig {
  settings: {
    submitLabel: string;
  };
  rows: Array<{ fields: IRowItem[] }>;
}

export type JSONString<T> = string & { __type__: T };

export interface IForm extends IBaseAttributes {
  account: string;
  name: string;
  state: EFormState;
  /** Form JSON */
  content_json: JSONString<IFormConfig>;
  /** Project ID */
  event: string;
  height: number;
  /** Thank you message JSON */
  post_submit_html: string;
  /** Content serving domain */
  redirect_domain: IRedirectDomain;
  stats: IFormStats;
  version: number;
  date_last_modified: string;
  date_last_published: string;
  last_published_version: number;
}

interface IPublishedForm extends IBaseAttributes {
  account: string;
  name: string;
  event: string;
  height: number;
  version: number;
  content_html: string;
  form: string;
  redirect: string;
  redirect_domain: string;
  short_code: string | undefined;
}

interface IShareData {
  height?: number;
  shortCode?: string;
}

export class Form extends DisplayModel<IForm> {
  public readonly className = 'Form';
  public override collection: Forms<this> | null = null;

  public get constraints(): TConstraints<IForm> {
    return {
      name: {
        presence: {
          allowEmpty: false,
        },
      },
    };
  }

  constructor(attributes: Partial<IForm> = {}) {
    super(attributes);

    makeObservable(this);
  }

  public getItemUrl(pathSuffix?: string): string {
    return concatPath(`/projects/${this.get('event')}/content/forms/${this.id}`, pathSuffix);
  }

  @computed
  public get name(): string {
    return this.get('name', '').trim() || 'Unnamed Form';
  }

  /**
   * Returns the content_json as an IFormConfig object for the Form Editor.
   */
  @computed
  public get formConfig(): IFormConfig {
    const content = this.get('content_json', '{}' as JSONString<IFormConfig>);
    return JSON.parse(content);
  }

  @computed
  public get published(): boolean {
    return this.get('state', EFormState.Draft) === EFormState.Published;
  }

  @computed
  public get usedFields(): IRowItem[] {
    return this.formConfig.rows?.flatMap((row) => row.fields) ?? [];
  }

  public setConfig(config: IFormConfig): void {
    this.set({ content_json: JSON.stringify(config) as JSONString<IFormConfig> });
  }

  public async publish(redirectDomainId: IRedirectDomain['id']): Promise<Form> {
    this.assertCollection(this.collection);

    return this.collection.publish(this, redirectDomainId);
  }

  public async getShareData(redirectDomainId: IRedirectDomain['id']): Promise<IShareData> {
    this.assertCollection(this.collection);

    const url = `${this.collection.url()}${this.id}/share?redirect_domain_id=${redirectDomainId}`;
    const response = await wretch<IPublishedForm>(url, {
      method: 'GET',
      headers: this.collection.getHeaders(),
    });

    if (isWretchError(response)) {
      // If there is no short_code it will return a 404 - this just means we haven't published to this domain yet and don't want to show an error
      if (response.error.errors.some((error) => error.status === 404)) {
        return {
          height: undefined,
          shortCode: undefined,
        };
      } else {
        throw response.error;
      }
    }

    return {
      height: response.data.height,
      shortCode: response.data.short_code,
    };
  }

  public async upsync(): Promise<IRachisMessage> {
    this.assertCollection(this.collection);

    // Set calculated height to dirty so it is saved onto all published forms
    this.setAttributeDirty('height');
    await this.patchDirty();

    const url = `${this.collection.url()}${this.id}/upsync`;
    const response = await wretch<IRachisMessage>(url, {
      method: 'POST',
      headers: this.collection.getHeaders(),
    });

    if (isWretchError(response)) {
      throw response.error;
    }

    return response.data;
  }
}

export class Forms<Model extends Form = Form> extends Collection<Model> {
  public getClassName(): string {
    return 'forms';
  }

  public getModel(attributes: Partial<Attributes<Model>>): Model {
    return new Form(attributes) as Model;
  }

  @action
  public async publish(model: Model, redirectDomainId: IRedirectDomain['id']): Promise<Model> {
    model.isUpdating = true;
    const response = await wretch<IForm>(`${this.url()}${model.id}/publish`, {
      method: 'POST',
      headers: this.getHeaders(),
      body: JSON.stringify({ redirect_domain_id: redirectDomainId }),
    });

    if (isWretchError(response)) {
      runInAction(() => {
        model.isUpdating = false;
        model.isErrored = true;
        model.error = response.error;
      });
      return model;
    }
    return this.processJSONResponse(response);
  }
}
