import { faExternalLink } from '@fortawesome/pro-regular-svg-icons';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { ToastType } from 'react-toastify';

import type { Form } from '@feathr/blackbox';
import { Button, Icon, Modal, toast } from '@feathr/components';
import { useLocalUrl, useStore } from '@feathr/extender/state';
import { errorMessage } from '@feathr/hooks';

import type { IOption } from './domains.utils';
import { getDomainComponent } from './domains.utils';
import FormCode from './FormCode';

import * as styles from './FormShareModal.css';

interface IProps {
  form: Form;
  isOpen: boolean;
  toggle: () => void;
}

function FormShareModal({ form, isOpen, toggle }: IProps): JSX.Element {
  const { t } = useTranslation();
  const { Domains } = useStore();
  const [activeTab] = useState<'embed' | 'feathr'>('embed');
  const [redirectDomainId, setRedirectDomainId] = useState<string | undefined>(undefined);
  const [contentDomain, setContentDomain] = useState<string | undefined>(undefined);
  const [shortCode, setShortCode] = useState<string | undefined>(undefined);
  const [height, setHeight] = useState<number | undefined>(200);
  const localUrl = useLocalUrl();
  const domains = Domains.list();

  function handleSelectDomain({ label, value }: IOption): void {
    setRedirectDomainId(value);
    setContentDomain(label);
  }

  async function handleGenerateShortCode(): Promise<void> {
    try {
      if (!redirectDomainId) {
        throw new Error('No domain selected');
      }
      await form.publish(redirectDomainId);
      const { shortCode, height } = await form.getShareData(redirectDomainId);
      setShortCode(shortCode);
      setHeight(height);
      toast(t('Form published successfully to {{- domain}}', { domain: contentDomain }), {
        type: ToastType.SUCCESS,
      });
    } catch (error) {
      toast(t('Failed to generate short code:\n{{- error}}', { error: errorMessage(error, t) }), {
        type: ToastType.ERROR,
      });
    }
  }

  useEffect(() => {
    async function getShortCode(): Promise<void> {
      if (redirectDomainId) {
        try {
          const { shortCode, height } = await form.getShareData(redirectDomainId);
          setShortCode(shortCode);
          setHeight(height);
        } catch (error) {
          toast(t('Failed to get short code:\n{{- error}}', { error: errorMessage(error, t) }), {
            type: ToastType.ERROR,
          });
        }
      }
    }

    getShortCode();
  }, [redirectDomainId]);

  const showLink = domains.models.length > 1;
  return (
    <Modal
      allowOverflow={true}
      bodyClassName={styles.root}
      onClose={toggle}
      opened={isOpen}
      rightActions={
        <Button onClick={toggle} type={'secondary'}>
          {t('Cancel')}
        </Button>
      }
      title={t('Share your form')}
    >
      {/* TODO: Reimplement tabs when Feathr embed is ready in #4565. */}
      {/* <Tabs>
        <Tab active={activeTab === 'embed'} onClick={handleClickEmbedTab} title={t('Embed code')} />
        <Tab active={activeTab === 'feathr'} onClick={handleClickFeathrTab} title={t('Feathr')} />
      </Tabs> */}
      {activeTab === 'embed' && (
        <div className={styles.embed}>
          <Trans t={t}>
            Forms can only be embedded on pages from domains that you've authorized Feathr to serve
            content from.
          </Trans>
          {showLink && (
            <Button
              className={styles.link}
              href={localUrl('/settings/account/domains')}
              suffix={<Icon icon={faExternalLink} />}
              target={'_blank'}
              type={'link'}
            >
              {t('View authorized domains')}
            </Button>
          )}
          {getDomainComponent({
            domains,
            selectedDomain: redirectDomainId,
            setSelectedDomain: handleSelectDomain,
            showLinks: false,
            t,
          })}
          {redirectDomainId && (
            <FormCode
              contentDomain={contentDomain}
              handleGenerateCode={handleGenerateShortCode}
              height={height}
              shortCode={shortCode}
            />
          )}
        </div>
      )}
      {/* TODO: Implement Feathr tab in #4565. */}
      {activeTab === 'feathr' && <div>Foo</div>}
    </Modal>
  );
}

export default observer(FormShareModal);
