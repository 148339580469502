import { faPlus } from '@fortawesome/pro-light-svg-icons';
import { faCloudUpload } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';

import { CampaignClass, EUsageMetrics } from '@feathr/blackbox';
import { Button, Select, toast, Toolbar } from '@feathr/components';
import Page from '@feathr/extender/App/Page';
import CampaignSelect from '@feathr/extender/components/CampaignSelect';
import FilterTags from '@feathr/extender/components/FilterTags';
import { useLocalUrl, useStore, useUsageAndQuota, useUser } from '@feathr/extender/state';
import { removeEmpty } from '@feathr/hooks';

import PartnersActions from './PartnersActions';
import PartnersFilters from './PartnersFilters';
import PartnersTable from './PartnersTable';

import 'react-datepicker/dist/react-datepicker.css';
import 'react-table/react-table.css';
import styles from './EventPartnersPage.css';

export const defaultPartnersColumns = [
  'logo',
  'name',
  'is_active',
  'activity',
  'email',
  'dashboard',
  'tag_ids',
  'options',
];

const activityOptions = [
  {
    name: 'Unmessaged',
    value: 'new',
  },
  {
    name: 'Unread',
    value: 'unread',
  },
  {
    name: 'Received message',
    value: 'received',
  },
  {
    name: 'Visited dashboard',
    value: 'visited',
  },
  {
    name: 'Shared collateral',
    value: 'shared',
  },
  {
    name: 'Generating leads',
    value: 'leads',
  },
  {
    name: 'Generating conversions',
    value: 'completed',
  },
];

// The filters stored to communicate the ui's state
export interface IFilters {
  is_active: boolean | null;
  name: string;
  parent: string | null;
  participation?: string;
  tag_ids: string[];
  activity?: string;
}

function getPartnerListFilters(
  state: { filters: IFilters; selected: string[] },
  eventId: string,
): Record<string, string | string[] | boolean | number> {
  const filters: Record<string, string | string[] | boolean | number> = {
    _parent: eventId,
  };
  if (state.filters.is_active !== null) {
    if (state.filters.is_active) {
      filters.is_active = true;
    } else {
      filters.is_active__ne = true;
    }
  }
  if (state.filters.tag_ids.length) {
    filters.tag_ids__in = state.filters.tag_ids;
  }
  if (state.filters.name) {
    filters.name__icontains = state.filters.name;
  }
  if (state.filters.participation) {
    filters.participation = state.filters.participation;
  }
  if (state.filters.activity) {
    filters[`campaign_states__${state.filters.activity}__exists`] = true;
    filters[`campaign_states__${state.filters.activity}__not__size`] = 0;
  }

  return filters;
}

function EventPartnersPage(): JSX.Element {
  const { Importers, Partners } = useStore();
  const history = useHistory();
  const localUrl = useLocalUrl();
  const { t } = useTranslation();
  const user = useUser();
  const { eventId, accountId } = useParams<{ eventId: string; accountId: string }>();
  const [filters, setFilters] = useState<IFilters>({
    parent: eventId,
    name: '',
    tag_ids: [],
    is_active: null,
  });
  const [columnIds, setColumnIds] = useState<string[]>(defaultPartnersColumns);
  const [selected, setSelected] = useState<string[]>([]);
  const numPartners = Partners.count({
    filters: {
      _parent: eventId,
      is_archived_ne: true,
    },
  });
  const { getUsageText } = useUsageAndQuota(
    accountId,
    [EUsageMetrics.PartnersByProject],
    numPartners,
  );

  useEffect(() => {
    if (!user.isPending) {
      const partnersColumnIds =
        user.getSetting<'partners_column_ids'>('partners_column_ids') ?? defaultPartnersColumns;
      setColumnIds(partnersColumnIds);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.isPending]);

  const updateColumnIds = (newColumnIds: string[]): void => {
    user.setSetting<'partners_column_ids'>('partners_column_ids', newColumnIds);
    setColumnIds(newColumnIds);
    user.patchDirty();
  };

  async function createPartner(): Promise<void> {
    const partner = Partners.create({
      event: eventId,
      parent: eventId,
      name: 'Unnamed Partner',
      email: '',
      ttd: {
        country_code: 'US',
      },
    });
    const response = await Partners.add(partner);
    history.push(localUrl(response.getItemUrl()));
  }

  async function createImporter(): Promise<void> {
    try {
      const importerAttrs: Record<string, string> = {
        event: eventId,
        created_by: user.id,
        kind: 'partner',
        state: 'draft',
      };

      const importer = Importers.create(importerAttrs);
      const response = await Importers.add(importer, { validate: false });
      history.push(localUrl(response.getItemUrl()));
    } catch (e) {
      toast('There was an error creating the import.', { type: 'error' });
    }
  }

  const actions = (
    <Toolbar>
      <Button onClick={createPartner} prefix={<FontAwesomeIcon icon={faPlus} />}>
        Add partner
      </Button>
      <Button onClick={createImporter} prefix={<FontAwesomeIcon icon={faCloudUpload} />}>
        Import partners
      </Button>
      <PartnersActions eventId={eventId} selected={selected} setSelected={setSelected} />
    </Toolbar>
  );

  const filterElements = [
    <>
      <FilterTags
        context={'partner'}
        onChange={(tagIds) => {
          setFilters({
            ...filters,
            tag_ids: tagIds,
          });
        }}
        value={filters.tag_ids}
        wrapperClassName={styles.filter}
      />
      <CampaignSelect
        className={styles.filter}
        eventId={eventId}
        isMulti={false}
        onChange={(value) => {
          setFilters({
            ...filters,
            participation: value ? value : undefined,
          });
        }}
        onClear={() => {
          setFilters({ ...filters, participation: undefined });
        }}
        placeholder={'Campaign'}
        type={CampaignClass.Referral}
        value={filters.participation ? filters.participation : undefined}
      />
      <Select
        getOptionLabel={(option) => option.name}
        getOptionValue={(option) => option.value}
        isClearable={true}
        name={'partner-stage'}
        onClear={() => setFilters({ ...filters, activity: undefined })}
        onSelectSingle={(option) => setFilters({ ...filters, activity: option.value })}
        options={activityOptions}
        placeholder={'Stage'}
        value={activityOptions.find((option) => option.value === filters.activity)}
        wrapperClassName={styles.filter}
      />
      <div style={{ alignItems: 'center', display: 'flex', marginLeft: '4px' }}>
        <span style={{ fontWeight: 'bold', marginRight: '8px' }}>Status:</span>
        <a
          className={classNames(styles.statusFilter, {
            [styles.current]: filters.is_active === null,
          })}
          onClick={() => setFilters({ ...filters, is_active: null })}
        >
          All
        </a>
        <a
          className={classNames(styles.statusFilter, {
            [styles.current]: filters.is_active === true,
          })}
          onClick={() => setFilters({ ...filters, is_active: true })}
        >
          Active
        </a>
        <a
          className={classNames(styles.statusFilter, {
            [styles.current]: filters.is_active === false,
          })}
          onClick={() => setFilters({ ...filters, is_active: false })}
        >
          Inactive
        </a>
      </div>
    </>,
    <>
      <PartnersFilters
        columnIds={columnIds}
        filters={filters}
        setFilters={setFilters}
        updateColumnIds={updateColumnIds}
      />
    </>,
  ];

  const tableFilters = removeEmpty(getPartnerListFilters({ filters, selected }, eventId));

  return (
    <Page actions={actions} secondaryText={getUsageText()} title={t('Partners')}>
      <PartnersTable
        columnIds={columnIds}
        filterElements={filterElements}
        filters={tableFilters}
        selected={selected}
        setSelected={setSelected}
      />
    </Page>
  );
}

export default observer(EventPartnersPage);
