import { when } from 'mobx';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useEffect, useState } from 'react';

import type { Form } from '@feathr/blackbox';
import { Spinner, toast } from '@feathr/components';
import { useStore } from '@feathr/extender/state';

import * as styles from './Preview.css';

interface IProps {
  form: Form;
}

function Preview({ form }: Readonly<IProps>): JSX.Element {
  const { Domains } = useStore();
  const [shortCode, setShortCode] = useState<string | undefined>(undefined);
  const [redirectDomainId, setRedirectDomainId] = useState<string | undefined>(undefined);
  const [contentDomain, setContentDomain] = useState<string | undefined>(undefined);

  useEffect(() => {
    async function getShortCode(): Promise<void> {
      try {
        const domains = Domains.list();
        await when(() => !domains.isPending);

        const readyDomains = domains.models
          .filter((domain) => domain.isContentServingReady)
          .map((domain) => ({
            id: domain.get('redirect').id,
            content_domain: domain.get('content_domain'),
          }));

        for (const domain of readyDomains) {
          const { shortCode, height } = await form.getShareData(domain.id);
          if (shortCode !== undefined && height !== undefined) {
            setShortCode(shortCode);
            setRedirectDomainId(domain.id);
            setContentDomain(domain.content_domain);
            break;
          }
        }
      } catch (error) {
        toast('Failed to get short code', { type: 'error' });
      }
    }

    getShortCode();
  }, [redirectDomainId]);

  // TODO: Refactor to use BE form preview. Raed only is currently controlled by CSS. https://github.com/Feathr/shrike/issues/4675

  return (
    <div className={styles.root}>
      <div className={styles.container}>
        {shortCode && contentDomain ? (
          <iframe
            aria-disabled={true}
            frameBorder={'none'}
            height={'100%'}
            src={`https://${contentDomain}/${shortCode}`}
            title={form.name}
            width={'100%'}
          />
        ) : (
          <Spinner className={styles.spinner} />
        )}
      </div>
    </div>
  );
}

export default observer(Preview);
