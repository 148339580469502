import { when } from 'mobx';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { Form, IPredicate } from '@feathr/blackbox';
import type { ISorted } from '@feathr/components';
import { Table, TableStatsCard, toast } from '@feathr/components';
import { useStore } from '@feathr/extender/state';
import { errorMessage } from '@feathr/hooks';

import type { IRow } from './EngagementColumns';
import { EngagementColumns } from './EngagementColumns';
import InteractionSelect from './InteractionSelect';

import * as styles from './Engagement.css';

interface IProps {
  form: Form;
}

function Engagement({ form }: Readonly<IProps>): JSX.Element {
  const { t } = useTranslation();
  const { Breadcrumbs } = useStore();
  const [isLoading, setIsLoading] = useState(true);
  const [rows, setRows] = useState<Array<IRow['original']> | undefined>(undefined);
  const [flavor, setFlavor] = useState<string | undefined>(undefined);
  const [sort, setSort] = useState<ISorted[] | undefined>(undefined);

  useEffect(() => {
    async function getRowData(): Promise<void> {
      try {
        const predicates: IPredicate[] = [
          {
            kind: 'activity',
            attr_against: 'form_id',
            attr_type: 'string',
            comparison: 'eq',
            value: form.id,
            unit: 'days',
            group: [],
            group_mode: 'match_all',
          },
        ];

        if (flavor) {
          predicates.push({
            kind: 'activity',
            attr_against: 'flvr',
            attr_type: 'string',
            comparison: 'eq',
            value: flavor,
          });
        }

        const crumbs = Breadcrumbs.list({
          predicates,
          mode: 'match_all',
          lookback_mode: 'unbounded',
          lookback_value: 1,
          ordering: sort ? sort.map((s) => (s.desc ? `-${s.id}` : s.id)) : ['-d_c'],
        });

        await when(() => crumbs.isLoaded);

        const rows = crumbs.models.map((crumb) => {
          return {
            crumb_id: crumb.id,
            crumb,
            person_id: crumb.get('per_id'),
            flavor: crumb.get('flvr'),
            timestamp: crumb.get('d_c'),
          };
        });

        setRows(rows);
      } catch (error) {
        toast(
          t('There was an error fetching engagement data:\n{{- error}}', {
            error: errorMessage(error, t),
          }),
        );
      } finally {
        setIsLoading(false);
      }
    }

    getRowData();
  }, [flavor, sort]);

  return (
    <TableStatsCard
      actions={[<InteractionSelect key={'flavor'} onChange={setFlavor} />]}
      className={styles.root}
      title={t('Form engagement')}
    >
      <Table
        columns={EngagementColumns(t)}
        idKey={'crumb_id'}
        isLoading={isLoading}
        items={rows ?? []}
        onSortChange={setSort}
        sort={sort}
      />
    </TableStatsCard>
  );
}

export default observer(Engagement);
