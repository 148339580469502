import classNames from 'classnames';
import type { TFunction } from 'i18next';
import type { JSX } from 'react';
import React from 'react';
import type { RowRenderProps } from 'react-table';

import type { Breadcrumb, IBreadcrumb } from '@feathr/blackbox';
import { EFlavorsRaw } from '@feathr/blackbox';
import type { IColumn } from '@feathr/components';
import { TableColumnHeader, Time } from '@feathr/components';
import PersonCell from '@feathr/extender/components/PersonCell';
import { TimeFormat } from '@feathr/hooks';

import * as tableStyles from '@feathr/components/dist/Table/Table.css';
import * as styles from './EngagementColumns.css';

interface ICrumbRow {
  /* Use crumb id as unique key for the row */
  crumb_id: IBreadcrumb['id'];
  crumb: Breadcrumb;
  person_id: IBreadcrumb['per_id'];
  flavor: IBreadcrumb['flvr'];
  timestamp: IBreadcrumb['d_c'];
}

export interface IRow extends RowRenderProps {
  original: ICrumbRow;
}

export function EngagementColumns(t: TFunction): Array<IColumn<ICrumbRow>> {
  return [
    {
      id: 'name',
      Header: TableColumnHeader({
        title: t('Name'),
      }),
      headerClassName: tableStyles.sort,
      className: classNames(tableStyles.cell, styles.person),
      Cell({ original: { crumb } }: IRow): JSX.Element {
        return <PersonCell original={crumb} />;
      },
    },
    {
      id: 'flvr',
      Header: TableColumnHeader({
        title: t('Interaction'),
        sortType: 'alpha',
      }),
      headerClassName: tableStyles.cellLeft,
      className: tableStyles.cell,
      Cell({ original: { flavor } }: IRow): JSX.Element {
        const flavorToLabelMap = {
          [EFlavorsRaw.form_submission]: t('Submission'),
          [EFlavorsRaw.form_view]: t('View'),
        };
        return <>{flavorToLabelMap[flavor]}</>;
      },
    },
    {
      id: 'd_c',
      Header: TableColumnHeader({
        title: t('Date and Time'),
        sortType: 'numeric',
      }),
      headerClassName: tableStyles.cellLeft,
      className: tableStyles.cell,
      Cell({ original: { timestamp } }: IRow): JSX.Element {
        return <Time format={TimeFormat.longDateTime} timestamp={timestamp} />;
      },
    },
  ];
}
