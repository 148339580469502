import { observer } from 'mobx-react-lite';
import type { FunctionComponent, JSX } from 'react';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import type { IConfig as IBaseConfig, IReportAttributes, ReportModel } from '@feathr/blackbox';
import { Spinner, Tooltip, VerifiedActivityToggle } from '@feathr/components';
import type { IUseReportExportReturn } from '@feathr/extender/hooks';
import { useLocalUrl } from '@feathr/extender/state';
import type { IReportProps } from '@feathr/report_components';
import { ReportContext, ReportDateRange } from '@feathr/report_components';

import * as styles from './ReportWrapper.css';

export interface IReportPageProps<
  IAttributes extends IReportAttributes,
  IConfig extends IBaseConfig,
> extends IUseReportExportReturn<IConfig> {
  Report: FunctionComponent<IReportProps>;
  hideCustomDateRange?: boolean;
  model: ReportModel<IAttributes>;
  showVerifiedActivityToggle?: boolean;
}

function ReportWrapper<IAttributes extends IReportAttributes, IConfig extends IBaseConfig>({
  Report,
  config,
  endFormatted,
  goals,
  handlers: exportHandlers,
  hasGoals,
  hideCustomDateRange = false,
  mode,
  model,
  reportEnd,
  reportStart,
  showVerifiedActivityToggle = false,
  startFormatted,
}: IReportPageProps<IAttributes, IConfig>): JSX.Element {
  const { t } = useTranslation();
  const localUrl = useLocalUrl();
  const reportContext = useContext(ReportContext);
  const {
    prioritizeVerifiedActivity,
    setPrioritizeVerifiedActivity,
    isLoading: isLoadingStatistics,
  } = reportContext || {};

  if (goals.isPending) {
    return <Spinner />;
  }

  return (
    <section>
      <div className={styles.toolbar}>
        <Tooltip
          position={'bottom-start'}
          title={hideCustomDateRange && t('Email campaign reports are live / cumulative only.')}
        >
          <ReportDateRange<IAttributes>
            disabled={isLoadingStatistics || hideCustomDateRange}
            mode={mode}
            model={model}
            reportEnd={reportEnd}
            reportStart={reportStart}
            setMode={exportHandlers.setMode}
            setReportEnd={exportHandlers.setReportEnd}
            setReportStart={exportHandlers.setReportStart}
          />
        </Tooltip>
        {showVerifiedActivityToggle && (
          <VerifiedActivityToggle
            disabled={isLoadingStatistics}
            onChange={setPrioritizeVerifiedActivity}
            value={prioritizeVerifiedActivity}
          />
        )}
      </div>
      <Report
        config={config}
        end={endFormatted}
        hasGoals={hasGoals}
        localUrl={localUrl}
        modelId={model.id}
        start={startFormatted}
      />
    </section>
  );
}

export default observer(ReportWrapper);
