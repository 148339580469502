import type { Dispatch, FocusEvent, JSX, SetStateAction } from 'react';
import React from 'react';

import { Button } from '@feathr/components';

interface IProps {
  label: string;
  setIsFocused: Dispatch<SetStateAction<boolean>>;
}

export default function SubmitButton({ label, setIsFocused }: Readonly<IProps>): JSX.Element {
  function handleClick(): void {
    setIsFocused(true);
  }

  function handleBlur(event: FocusEvent<HTMLButtonElement>): void {
    if (event.relatedTarget === null) {
      setIsFocused(false);
    }
  }

  return (
    <Button onBlur={handleBlur} onClick={handleClick} type={'primary'}>
      {label}
    </Button>
  );
}
