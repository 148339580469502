import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button, CodeMirrorElement, CopyToClipboardButton } from '@feathr/components';

import * as styles from './FormCode.css';

interface IProps {
  handleGenerateCode: () => Promise<void>;
  contentDomain?: string;
  height?: number;
  shortCode?: string;
}

function FormCode({
  handleGenerateCode,
  contentDomain,
  height,
  shortCode,
}: Readonly<IProps>): JSX.Element {
  const { t } = useTranslation();

  if (!shortCode) {
    return (
      <Button name={'generate-code'} onClick={handleGenerateCode} type={'primary'}>
        {t('Generate code')}
      </Button>
    );
  }

  function shortCodeToiFrame(): string {
    return `<iframe src="https://${contentDomain}/${shortCode}" width="100%" height="${height}" frameborder="0"/>`;
  }

  const value = shortCodeToiFrame();

  return (
    <>
      <CodeMirrorElement
        className={styles.code}
        name={'short_code'}
        options={{ lineWrapping: true, readOnly: true }}
        value={value}
      />
      <CopyToClipboardButton
        className={styles.copy}
        name={'copy-iframe'}
        t={t}
        text={shortCodeToiFrame()}
        type={'primary'}
      />
    </>
  );
}

export default FormCode;
