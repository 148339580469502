import { type IObservableArray } from 'mobx';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import type {
  Account,
  Billable,
  DisplayCreative,
  Event,
  Goal,
  Partner,
  Segment,
  Targetable,
  Targeting,
  TTDCampaign,
  User,
} from '@feathr/blackbox';
import { CardV2 as Card, Form } from '@feathr/components';
import { useFlags, useStore, useUser } from '@feathr/extender/state';
import type { Model } from '@feathr/rachis';

import type { ICampaignValidationErrors } from '../../../CampaignSummary';
import CampaignSummary from '../../../CampaignSummary';
import { getGoalSegments } from '../../StepGoals';
import type { ICampaignDetails } from '../AdWizard';
import { getGeoFilters } from '../AdWizardFiltersStep/AdWizardFiltersStep';
import { getTargetables, getTargetSegments } from '../AdWizardTargetsStep';
import TTDRecommendedOptimizationModal from '../TTDRecommendedOptimizationModal';

import * as styles from '../AdWizard.css';

interface IProps {
  campaign: TTDCampaign;
  targets: IObservableArray<Targeting>;
  goals: IObservableArray<Goal>;
  creatives: IObservableArray<DisplayCreative>;
  billable?: Billable;
  account: Account;
  event: Event;
  validate: (
    details: ICampaignDetails,
    user?: User,
    noMaxBudget?: boolean,
  ) => ICampaignValidationErrors;
}

export function validatePartner(partner: Partner): string[] {
  return partner.validate([], false).errors;
}

function AdWizardReviewStep({
  campaign,
  targets,
  goals,
  creatives,
  billable,
  account,
  event,
  validate,
}: Readonly<IProps>): JSX.Element {
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const additionalBudgetParam = Number(queryParams.get('additionalBudget'));
  const { Segments, Targetables } = useStore();
  const flags = useFlags();
  const user = useUser();
  const { isSegmentCampaign, isPastEndDate, isMonetization, isPublished } = campaign;
  const { t } = useTranslation();

  // Never prompt the user to increase budget if the campaign has reached its end date
  const showRecommendationsModal =
    !!additionalBudgetParam && !isPastEndDate && !isMonetization && isPublished;

  const geoFilters = getGeoFilters(targets, Targetables);
  const goalSegments = getGoalSegments(goals, Segments);

  const targetables = isSegmentCampaign
    ? getTargetSegments(targets, Segments)
    : getTargetables(targets, Targetables);

  const errors = validate(
    {
      account,
      billable,
      campaign,
      creatives,
      event,
      goals,
      goalSegments,
      targetables: !isSegmentCampaign ? (targetables as Targetable[]) : undefined,
      targetings: targets,
      targetingSegments: isSegmentCampaign ? (targetables as Segment[]) : undefined,
    },
    user,
    flags.noMaxBudget,
  );

  // Converting observables back to vanilla JavaScript.
  const grandchildModels: Model[] = [...goalSegments, ...geoFilters];
  const childModels: Model[] = [...creatives.slice(), ...goals.slice(), ...targets.slice()];
  if (targetables.length) {
    if (campaign.isSegmentCampaign) {
      grandchildModels.push(...targetables);
    } else {
      childModels.push(...targetables);
    }
  }

  function handleValidate(): ICampaignValidationErrors {
    return validate(
      {
        account,
        billable,
        campaign,
        creatives,
        event,
        goals,
        goalSegments,
        targetables: !isSegmentCampaign ? (targetables as Targetable[]) : undefined,
        targetings: targets,
        targetingSegments: isSegmentCampaign ? (targetables as Segment[]) : undefined,
      },
      user,
      flags.noMaxBudget,
    );
  }

  return (
    <Form className={styles.formRoot} label={'Edit Campaign: Review'}>
      {showRecommendationsModal && (
        <TTDRecommendedOptimizationModal campaign={campaign} validate={handleValidate} />
      )}
      <Card width={'narrow'}>
        <Card.Header
          description={t('Review the summary below to make sure everything is set up correctly.')}
          title={t('Review')}
        />
        <Card.Content>
          <CampaignSummary campaign={campaign} layout={'vertical'} validationErrors={errors} />
        </Card.Content>
      </Card>
    </Form>
  );
}

export default observer(AdWizardReviewStep);
